import { Box, TablePagination, Tooltip } from '@material-ui/core'
import { Table } from 'components/common/Table'
import { useGlobalStyles } from 'hooks/useGlobalStyles'
import usePagination from 'hooks/usePagination'
import { Column } from 'material-table'
import React, { useMemo, useState } from 'react'
import HolcimConfigurationModal from './components/HolcimConfigurationModal'
import { useQuery } from '@apollo/react-hooks'
import {
  GetPagingSupplierIntegrationsQuery,
  GetPagingSupplierIntegrationsQueryVariables,
  SupplierIntegration
} from 'generated/graphql'
import { GET_PAGING_SUPPLIER_INTEGRATIONS } from './graphql'
import isArray from 'lodash/isArray'
import { EditSVGIcon, WarningIcon } from 'components/common/icons'
import { getFieldSorter } from 'utils/tools'
import { withPermissionGuard } from 'HoCs'
import { Privileges } from 'utils/rolesAndPrivileges'
import { IntegrationProviderName } from './types'

export const Integrations = withPermissionGuard(
  () => {
    const classes = useGlobalStyles()
    const { currentPageSize, onChangeRowsPerPage, currentPage, onChangePage, sorter, setSorter } = usePagination(
      'integrations'
    )
    const [supplierIntegration, setSupplierIntegration] = useState<SupplierIntegration | null>(null)

    const { data: supplierIntegrations, loading, refetch } = useQuery<
      GetPagingSupplierIntegrationsQuery,
      GetPagingSupplierIntegrationsQueryVariables
    >(GET_PAGING_SUPPLIER_INTEGRATIONS, {
      variables: {
        sorter,
        pagination: {
          pageNumber: currentPage,
          pageSize: currentPageSize
        }
      },
      fetchPolicy: 'cache-and-network'
    })

    const columns: Column<SupplierIntegration>[] = useMemo(
      () => [
        {
          title: 'Job Number',
          field: 'Project.ProjectNo',
          render: row => row?.project?.ProjectNo
        },
        {
          title: 'Job Name',
          field: 'Project.Name',
          render: row => row?.project?.Name
        },
        {
          title: 'Company',
          field: 'Company.Name',
          render: row => row?.project?.company?.Name
        },
        {
          title: 'Supplier Integration',
          field: 'supplierProvider.Supplier',
          sorting: false
        },
        {
          title: 'Warning',
          render: row =>
            (!row.IsActive || !row.AreCredentialsSetUp) &&
            row.supplierProvider?.Supplier === IntegrationProviderName.Holcim ? (
              <Tooltip
                title={
                  !row.AreCredentialsSetUp
                    ? 'Credentials setup incomplete'
                    : 'Supplier integration inactive in job management'
                }
              >
                <WarningIcon width={32} height={32} />
              </Tooltip>
            ) : (
              ''
            ),
          sorting: false
        }
      ],
      []
    )

    return (
      <Box>
        <Table<SupplierIntegration>
          className={classes.table}
          isLoading={loading}
          columns={columns}
          data={supplierIntegrations?.getPagingSupplierIntegrations?.items ?? []}
          actions={[
            rowData => ({
              icon: () => <EditSVGIcon />,
              onClick: (_, row) => {
                if (!isArray(row)) {
                  setSupplierIntegration(row)
                }
              },
              position: 'row',
              tooltip: 'Edit',
              hidden: rowData.supplierProvider?.Supplier === IntegrationProviderName.Hanson
            })
          ]}
          isShowCustomEmptyData={!supplierIntegrations?.getPagingSupplierIntegrations?.items?.length}
          emptyDescription="No news found"
          option={{
            pageSize: currentPageSize,
            search: false,
            selection: false,
            toolbar: false,
            minBodyHeight: `calc(98vh - 24rem)`,
            maxBodyHeight: `calc(98vh - 24rem)`,
            rowStyle: {
              height: '3rem'
            },
            headerStyle: {
              fontWeight: 600
            }
          }}
          onOrderChange={(columnIndex, orderDirection) =>
            setSorter(
              orderDirection
                ? {
                    orderBy: orderDirection,
                    field: getFieldSorter<SupplierIntegration>(columnIndex, columns)
                  }
                : undefined
            )
          }
          components={{
            Pagination: props => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPage={currentPageSize}
                  count={supplierIntegrations?.getPagingSupplierIntegrations?.pagination?.total ?? 0}
                  page={currentPage - 1}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={e => {
                    onChangeRowsPerPage(e, props)
                  }}
                />
              )
            }
          }}
        />
        {supplierIntegration && (
          <HolcimConfigurationModal
            supplierIntegration={supplierIntegration}
            setSupplierIntegration={setSupplierIntegration}
            refetch={refetch}
          />
        )}
      </Box>
    )
  },
  { page: 'Integrations', privileges: [Privileges.ManageIntegrations] }
)
