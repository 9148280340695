import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { Box, Button, Grid, TablePagination, TextField, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { DeleteIcon, EditIcon, ExcelSVGIcon, SearchIcon } from 'components/common/icons'
import {
  DeletePublicationMutation,
  DeletePublicationMutationVariables,
  ExportPublicationsQuery,
  ExportPublicationsQueryVariables,
  GetPagingPublicationsQuery,
  GetPagingPublicationsQueryVariables,
  NewsArticle,
  Publication
} from 'generated/graphql'
import { DELETE_PUBLICATION, GET_PAGING_PUBLICATIONS } from 'graphql/common'
import usePagination from 'hooks/usePagination'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import { Column } from 'material-table'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { formatDateInDDMMMYYYY } from 'utils/dates'
import { Table } from '../../../components/common/Table'
import { Feedback, getErrorFeedback, getSuccessFeedback } from '../../../utils/feedback'
import { NewsAlertForm } from './NewsAlertForm'
import { EXPORT_PUBLICATIONS } from './graphql'
import classNames from 'classnames'
import { useCMSList } from '../cms/styles'

interface NewsAlertState {
  currentToggle: NewsAlertToggle
  publicationType: string
  autoHideFeedback: Feedback | null
  isShowNewsAlertForm: boolean
}

export type NewsAlertToggle = 'News' | 'Alert'

type NewsAlertProps = {
  setNewsArticle: Dispatch<SetStateAction<NewsArticle | null>>
  newsArticle: NewsArticle | null
}

export const NewsAlert = ({ setNewsArticle, newsArticle }: NewsAlertProps) => {
  const { setSuccessMessage } = useSnackbarContext()
  const classes = useCMSList()
  const [state, setState] = useState<NewsAlertState>({
    currentToggle: 'News',
    publicationType: 'N',
    autoHideFeedback: null,
    isShowNewsAlertForm: false
  })
  const [selectedItem, setSelectedItem] = useState<Publication | null>(null)
  const [deletedItem, setDeletedItem] = useState<Publication | null>(null)
  const { publicationType, autoHideFeedback, isShowNewsAlertForm } = state

  const { currentPageSize, onChangeRowsPerPage, currentPage, onChangePage, filter, onFilter } = usePagination(
    'publicationTable'
  )

  const { loading, data, refetch: refetchData } = useQuery<
    GetPagingPublicationsQuery,
    GetPagingPublicationsQueryVariables
  >(GET_PAGING_PUBLICATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      publicationType: publicationType,
      filter: filter.trim(),
      pagination: {
        pageNumber: currentPage,
        pageSize: currentPageSize
      }
    }
  })

  const [removePublication] = useMutation<DeletePublicationMutation, DeletePublicationMutationVariables>(
    DELETE_PUBLICATION
  )

  const [exportPublication] = useLazyQuery<ExportPublicationsQuery, ExportPublicationsQueryVariables>(
    EXPORT_PUBLICATIONS,
    {
      onCompleted({ exportPublications }) {
        setSuccessMessage(exportPublications)
      },
      fetchPolicy: 'network-only'
    }
  )

  const columns: (Column<Publication> & { width?: string })[] = [
    {
      field: 'PublicationTitle',
      title: 'Title',
      width: '40%'
    },
    {
      field: 'PublishedDateTime',
      title: 'Published',
      render: row => (row.PublishedDateTime ? formatDateInDDMMMYYYY(new Date(row.PublishedDateTime)) : ''),
      width: '20%'
    },
    {
      field: 'PublishedBy',
      title: 'Author',
      width: '20%'
    },
    {
      field: 'ViewCount',
      title: 'Views',
      width: '10%'
    },
    {
      field: 'LikeCount',
      title: 'Likes',
      width: '10%'
    }
  ]

  const onCloseDeletePopup = () => {
    setDeletedItem(null)
  }

  const onDelete = () => {
    if (deletedItem) {
      removePublication({
        variables: {
          input: {
            ID: deletedItem?.ID
          }
        }
      })
        .then(({ data }) => {
          if (data?.deletePublication) {
            setState({
              ...state,
              autoHideFeedback: getSuccessFeedback(
                `Publication "${deletedItem?.PublicationTitle}" deleted successfully`
              )
            })
            refetchData()
          }
        })
        .catch(error => {
          setState({ ...state, autoHideFeedback: getErrorFeedback(error.message) })
        })
    }
  }

  const handleExportExcelClick = () => {
    exportPublication({
      variables: {
        publicationType: state?.publicationType,
        searchText: filter
      }
    })
  }

  return (
    <>
      {isShowNewsAlertForm || newsArticle ? (
        <NewsAlertForm
          newsArticle={newsArticle}
          selectedItem={selectedItem}
          onBack={() => {
            setState({ ...state, isShowNewsAlertForm: false })
            setSelectedItem(null)
            setNewsArticle(null)
          }}
          refetchData={refetchData}
          totalInCarousel={data?.getPagingPublications.items.at(0)?.TotalInCarousel ?? 0}
        />
      ) : (
        <>
          <Grid container direction="row" justifyContent="space-between">
            <Grid
              item={true}
              container
              direction="row"
              xs={6}
              lg={7}
              md={6}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box className="flex gap-3">
                <TextField
                  className="flex w-144 border-thin-rounded-input rounded-input"
                  placeholder="Search"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <SearchIcon />
                  }}
                  onChange={e => {
                    onFilter(e.target.value)
                  }}
                />
              </Box>
            </Grid>
            <Box className="flex gap-3">
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  handleExportExcelClick()
                }}
              >
                <ExcelSVGIcon className="mr-2" /> Export
              </Button>
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={() =>
                  setState({
                    ...state,
                    isShowNewsAlertForm: true
                  })
                }
              >
                Create Publication
              </Button>
            </Box>
          </Grid>
          <Grid className={classNames('mt-5', classes.table)}>
            <Table
              isLoading={loading}
              columns={columns}
              data={(data?.getPagingPublications?.items as Publication[]) || []}
              option={{
                rowStyle: rowData => ({
                  backgroundColor: rowData.PublicationStatus === 'D' ? '#EEE' : '#FFF'
                }),
                sorting: false,
                headerStyle: {
                  fontWeight: 600
                },
                toolbar: false
              }}
              actions={[
                {
                  icon: () => (
                    <Box className="flex">
                      <EditIcon fontSize="small" color="secondary" />
                      <Typography color="secondary" variant="body2">
                        Edit
                      </Typography>
                    </Box>
                  ),
                  tooltip: 'Edit',
                  onClick: (_event, rowData: Publication | Publication[]) => {
                    if (!Array.isArray(rowData)) {
                      setSelectedItem(rowData)
                      setState({ ...state, isShowNewsAlertForm: true })
                    }
                  }
                },
                {
                  icon: () => <DeleteIcon color="secondary" fontSize="small" />,
                  tooltip: 'Remove',
                  onClick: (_event, rowData: Publication | Publication[]) => {
                    if (!Array.isArray(rowData)) setDeletedItem(rowData)
                  }
                }
              ]}
              components={{
                Pagination: props => {
                  return (
                    <TablePagination
                      {...props}
                      rowsPerPageOptions={[10, 20, 50]}
                      rowsPerPage={currentPageSize}
                      count={data?.getPagingPublications.pagination?.total || 0}
                      page={currentPage - 1}
                      onChangePage={onChangePage}
                      onChangeRowsPerPage={e => {
                        onChangeRowsPerPage(e, props)
                      }}
                    />
                  )
                }
              }}
            />
          </Grid>
        </>
      )}

      {!loading && !data?.getPagingPublications?.items?.length ? (
        <Alert severity="info">
          <AlertTitle>No Publication Found</AlertTitle>
          Add your first publication.
        </Alert>
      ) : null}
      <ConfirmDialog
        buttonText="Delete"
        handleClose={onCloseDeletePopup}
        open={!!deletedItem}
        onConfirm={onDelete}
        title="Delete Publication"
        description="Are you sure to delete this job from your publication?"
      />
      <AutoHideSnackBar
        autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
        handleClose={() => setState({ ...state, autoHideFeedback: null })}
        message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
        severity={autoHideFeedback?.type}
        open={!!autoHideFeedback}
      />
    </>
  )
}
