import React, { createRef, useMemo, useState } from 'react'
import { Grid, styled, Button, Typography, Link, Breadcrumbs, Box } from '@material-ui/core'
import { LINKED_SITE_WEB_ENDPOINT, S4 } from '../../../utils/Constants'
import {
  NewsArticle,
  Publication,
  UpsertPublicationMutation,
  UpsertPublicationMutationVariables
} from '../../../generated/graphql'
import { useMutation } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { Formik, getIn } from 'formik'
import * as yup from 'yup'
import { GridLayoutInput } from '../../../components/common/GridLayoutInput'
import ReactQuill, { Quill } from 'react-quill'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'
import { Feedback, getSuccessFeedback, getErrorFeedback } from '../../../utils/feedback'
import aws from 'aws-sdk'
import { NavigateNextIcon } from '../../../components/common/icons'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { authConfig } from '../../../config/authConfig'
import UploadImage from '../../../components/common/UploadImage'
import { NOAUTOFILL } from 'components/common/AutocompleteInput'
import { GridLayoutAutoCompleteTagsInput } from 'components/common/GridLayoutAutoCompleteTagsInput'

const UPSERT_PUBLICATION = loader('../../../graphql/common/upsertPublication.graphql')
const publicationStatus = ['D', 'P']
const ToggleOptions = ['true', 'false']
const articlePriority = Array.from({ length: 10 }, (_, i) => i + 1)

const GridItem = styled(Grid)({
  '& .MuiPaper-root': {
    paddingLeft: '1.8125rem',
    paddingRight: '1.8125rem'
  }
})

const SubmitButton = styled(Button)({
  margin: 10,
  textAlign: 'center'
})

const Text = styled(Typography)({
  fontWeight: 'bold',
  fontFamily: 'Bliss Pro'
})
const TitleText = styled(Typography)({
  padding: '0px !important',
  marginBottom: 10
})

type StatusType = 'D' | 'P'

const REQUIRED = `is a required field`
const TEXT_MAX = 30000
const TITLE_MAX = 250

const publicationSchema = yup.object({
  Title: yup
    .string()
    .nullable()
    .trim()
    .max(TITLE_MAX, `${TITLE_MAX} chars limit reached`)
    .required(`Publication title ${REQUIRED}`),
  Author: yup
    .string()
    .nullable()
    .trim()
    .max(200, 'Publication author max length 200 characters')
    .required(`Publication author ${REQUIRED}`),
  Text: yup
    .string()
    .nullable()
    .trim()
    // we're skipping this for now
    //.max(TEXT_MAX, `${TEXT_MAX} chars limit reached`)
    .required(`Publication text ${REQUIRED}`),
  URL: yup.string().test('match', 'Invalid URL', function(url) {
    if (url) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i' // fragment locator
      )
      return !!pattern.test(url)
    }
    return true
  }),
  PhotoData: yup
    .string()
    .required(`Please upload an image`)
    .typeError(`Please upload an image`),
  Tags: yup
    .array()
    .of(yup.string().matches(/^[a-zA-Z0-9]+$/, 'Tag only numbers and letters can be entered.'))
    .nullable()
})

interface FormValues {
  Title: string
  Author: string
  Text: string
  Status: StatusType
  PhotoData: string | null
  URL: string | null
  KeepToTop: 'true' | 'false'
  Tags: string[]
  IsVisibleToAllUsers: 'true' | 'false'
  KeepAlwaysOnTop: 'true' | 'false'
  Priority: number | null
}

export interface NewsAlertFormPropsProps {
  selectedItem: Publication | null
  onBack: () => void
  refetchData: () => void
  newsArticle: NewsArticle | null
  totalInCarousel: number
}

const Font = Quill.import('formats/font')
const Size = Quill.import('attributors/style/size')

const fontSizes = [
  '16px',
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '22px',
  '24px',
  '26px',
  '28px',
  '36px',
  '40px',
  '48px',
  '72px'
]

const fontFamily = ['arial', 'sans-serif', 'univers']

Font.whitelist = fontFamily
Quill.register(Font, true)

Size.whitelist = fontSizes
Quill.register(Size, true)

export const NewsAlertForm = ({
  selectedItem,
  onBack,
  refetchData,
  newsArticle,
  totalInCarousel
}: NewsAlertFormPropsProps) => {
  const [autoHideFeedback, setAutoHideFeedback] = useState<Feedback | null>(null)
  const [isRemovePreviewImage, setIsRemovePreviewImage] = useState<boolean>(false)

  const quillRef = createRef<ReactQuill>()

  aws.config.update({
    region: authConfig.s3Bucket.region,
    accessKeyId: authConfig.s3Bucket.accessKey,
    secretAccessKey: authConfig.s3Bucket.accessSecret
  })

  const S3Bucket = new aws.S3()

  const checkCharacterCount = () => {
    if (quillRef && quillRef.current) {
      const editor = quillRef.current.getEditor()
      if (editor.getLength() > TEXT_MAX) {
        quillRef.current.getEditor().deleteText(TEXT_MAX, editor.getLength())
      }
    }
  }

  const quillSettings = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        [{ font: fontFamily }, { size: fontSizes }],
        ['bold', 'italic', 'underline', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {
        image: () => {
          if (quillRef && quillRef.current) {
            const input = document.createElement('input')
            input.setAttribute('type', 'file')
            input.setAttribute('accept', 'image/*')
            input.click()
            input.onchange = async function() {
              if (input && input.files) {
                const file = input.files[0]
                const extension = file.name.split('.').pop(),
                  fileName = file.name.split('.' + extension)[0],
                  objKey = fileName + '-' + Date.now() + '.' + extension

                S3Bucket.upload({ Bucket: authConfig.s3Bucket.name, Key: objKey, Body: file }, function(err, data) {
                  if (err) {
                    console.log('Error', err)
                  }
                  if (data) {
                    if (quillRef && quillRef.current) {
                      const range = quillRef.current.getEditor().getSelection()
                      if (range) quillRef.current.getEditor().insertEmbed(range?.index, 'image', data.Location, 'user')
                    }
                  }
                })
              }
            }
          }
        }
      }
    },
    clipboard: {
      matchVisual: false
    }
  }

  const initialValues: FormValues = useMemo(
    () => ({
      Title: selectedItem?.PublicationTitle ?? newsArticle?.Title ?? '',
      Author: selectedItem?.PublishedBy || 'ACCIONA ANZ',
      Text: selectedItem?.PublicationText || '',
      Status: (selectedItem?.PublicationStatus as StatusType) || 'D',
      PhotoData: selectedItem?.PhotoData || '',
      URL: selectedItem?.URL ?? (newsArticle?.ID ? `${LINKED_SITE_WEB_ENDPOINT}/article/${newsArticle?.ID}` : ''),
      KeepToTop: selectedItem?.KeepToTop ? 'true' : 'false',
      Tags: selectedItem?.Tags || [],
      IsVisibleToAllUsers: selectedItem?.IsVisibleToAllUsers ? 'true' : 'false',
      KeepAlwaysOnTop: selectedItem && selectedItem.CarouselOrder !== null ? 'true' : 'false',
      Priority: selectedItem?.CarouselOrder ?? null
    }),
    [selectedItem, newsArticle]
  )

  const [upsertPublication, { loading: isLoadingUpsertPublication }] = useMutation<
    UpsertPublicationMutation,
    UpsertPublicationMutationVariables
  >(UPSERT_PUBLICATION, {
    update(cache, result) {
      const upsertPublication = result.data?.upsertPublication
      if (upsertPublication) {
      }
    }
  })

  const upsertPublicationForm = ({
    Title,
    Text,
    Author,
    Status,
    PhotoData,
    URL,
    KeepToTop,
    Tags,
    IsVisibleToAllUsers,
    Priority,
    KeepAlwaysOnTop
  }: FormValues) => {
    if (selectedItem) {
      const publishedDate = Status === 'D' ? null : new Date()
      upsertPublication({
        variables: {
          input: {
            ID: selectedItem && selectedItem?.ID,
            PublicationTitle: Title,
            PublicationText: Text,
            PublicationStatus: Status,
            PublicationType: 'N',
            PublishedBy: Author,
            PublishedDateTime: publishedDate,
            PhotoData,
            URL,
            KeepToTop: KeepToTop === 'true',
            tags: Tags,
            IsVisibleToAllUsers: IsVisibleToAllUsers === 'true',
            CarouselOrder: KeepAlwaysOnTop === 'true' ? Priority : null
          }
        }
      })
        .then(({ data }) => {
          if (data?.upsertPublication) {
            setIsRemovePreviewImage(true)
            setAutoHideFeedback(
              getSuccessFeedback(`Publication "${data?.upsertPublication.PublicationTitle}" updated successfully`)
            )
            refetchData()
            onBack()
          }
        })
        .catch(error => setAutoHideFeedback(getErrorFeedback(error.message)))
        .finally(() => setIsRemovePreviewImage(false))
    } else {
      const publishedDate = Status === 'D' ? null : new Date()
      upsertPublication({
        variables: {
          input: {
            PublicationTitle: Title,
            PublicationText: Text,
            PublicationStatus: Status,
            PublicationType: 'N',
            PublishedBy: Author,
            PublishedDateTime: publishedDate,
            PhotoData,
            URL,
            KeepToTop: KeepToTop === 'true',
            tags: Tags,
            IsVisibleToAllUsers: IsVisibleToAllUsers === 'true',
            CarouselOrder: KeepAlwaysOnTop === 'true' ? Priority : null
          }
        }
      })
        .then(({ data }) => {
          if (data?.upsertPublication) {
            setIsRemovePreviewImage(true)
            setAutoHideFeedback(
              getSuccessFeedback(`Publication "${data?.upsertPublication.PublicationTitle}" added successfully`)
            )
            refetchData()
            onBack()
          }
        })
        .catch(error => setAutoHideFeedback(getErrorFeedback(error.message)))
        .finally(() => setIsRemovePreviewImage(false))
    }
  }
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
  }

  return (
    <>
      <Grid container direction="row">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" onClick={onBack}>
            <Typography variant="body2" color="primary">
              Publications
            </Typography>
          </Link>
          <Link color="inherit" href="/manage-comms/publication" onClick={handleClick}>
            <Typography variant="body2" style={{ fontWeight: 'bold' }} color="textPrimary">
              {selectedItem ? 'Update Publication' : 'Create Publication'}
            </Typography>
          </Link>
        </Breadcrumbs>
      </Grid>
      <Grid container>
        <TitleText variant="button"></TitleText>
      </Grid>
      <Formik
        onSubmit={values => {
          upsertPublicationForm(values)
        }}
        initialValues={initialValues}
        validationSchema={publicationSchema}
        enableReinitialize
      >
        {props => {
          const { values, errors, setFieldValue, touched, handleSubmit, getFieldProps } = props
          const tagErrors: string[] = getIn(errors, 'Tags')
          const tagError = tagErrors?.filter(item => !!item)[0]
          return (
            <form className="w-full mt-4" onSubmit={handleSubmit}>
              <Grid container justifyContent="space-between" direction="row" spacing={S4}>
                <Grid container alignContent="center" justifyContent="space-between" className="p-4" direction="row">
                  <Grid item xs={10}>
                    <GridLayoutInput
                      fullWidth
                      id="Title"
                      inputProps={{ maxLength: TITLE_MAX + 1 }}
                      label="Enter publication title"
                      name="Title"
                      textFieldBreakpoints={{ xs: 12, sm: 12 }}
                      rows={4}
                    />
                  </Grid>

                  <ToggleButtonGroup
                    exclusive
                    {...getFieldProps('Status')}
                    onChange={(e, value) => {
                      if (value !== null) {
                        setFieldValue('Status', value)
                      }
                    }}
                    aria-label="type"
                  >
                    {publicationStatus.map(e => (
                      <ToggleButton value={e} aria-label={e} key={e} id={e}>
                        <Typography>{e === 'D' ? 'Draft' : 'Publish'}</Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                <Grid container alignContent="center" justifyContent="space-between" className="p-4" direction="row">
                  <Grid item xs={9}>
                    <GridLayoutInput
                      fullWidth
                      id="URL"
                      inputProps={{ maxLength: TITLE_MAX + 1 }}
                      label="Enter publication URL"
                      name="URL"
                      textFieldBreakpoints={{ xs: 12, sm: 12 }}
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <GridLayoutInput fullWidth label="Enter author's name" name="Author" id="Author" />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <GridLayoutAutoCompleteTagsInput
                    name="Tags"
                    textFieldBreakpoints={{ xs: 12 }}
                    options={[]}
                    textFieldProps={{
                      fullWidth: true,
                      variant: 'outlined',
                      autoComplete: NOAUTOFILL,
                      label: 'Tags',
                      placeholder: 'Press enter for multiple tags'
                    }}
                    renderTagsProps={{
                      variant: 'default'
                    }}
                    autoComplete={NOAUTOFILL}
                    multiple
                    error={!!tagError && !!touched.Tags}
                    helperText={tagError && touched.Tags ? tagError : null}
                  />
                </Grid>
                <Grid item xs={3} className="flex items-center">
                  <Box className="flex flex-col">
                    <b>Make publication visible to ALL users</b>
                    <b>(Internal Acciona and external users)</b>
                  </Box>
                  <ToggleButtonGroup
                    exclusive
                    {...getFieldProps('IsVisibleToAllUsers')}
                    onChange={(e, value) => {
                      if (value !== null) {
                        setFieldValue('IsVisibleToAllUsers', value)
                      }
                    }}
                    aria-label="type"
                    className="ml-3"
                  >
                    {ToggleOptions.map(e => (
                      <ToggleButton value={e} aria-label={e} key={e} id={e}>
                        <Typography>{e === 'true' ? 'Yes' : 'No'}</Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={3} className="flex items-center gap-2">
                  <b>Display in carousel?</b>
                  <ToggleButtonGroup
                    exclusive
                    {...getFieldProps('KeepToTop')}
                    onChange={(e, value) => {
                      if (value !== null) {
                        setFieldValue('KeepToTop', value)
                      }
                    }}
                    aria-label="type"
                  >
                    {ToggleOptions.map(e => (
                      <ToggleButton value={e} aria-label={e} key={e} id={e} disabled={totalInCarousel === 10}>
                        <Typography>{e === 'true' ? 'Yes' : 'No'}</Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={6} className="flex items-center gap-2">
                  <b>Always keep in carousel?</b>
                  <ToggleButtonGroup
                    exclusive
                    {...getFieldProps('KeepAlwaysOnTop')}
                    onChange={(e, value) => {
                      if (value !== null) {
                        setFieldValue('KeepAlwaysOnTop', value)
                      }
                    }}
                    aria-label="type"
                  >
                    {ToggleOptions.map(e => (
                      <ToggleButton value={e} aria-label={e} key={e} id={e}>
                        <Typography>{e === 'true' ? 'Yes' : 'No'}</Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                  &nbsp;&nbsp;&nbsp;
                  {values.KeepAlwaysOnTop === 'true' && (
                    <ToggleButtonGroup
                      exclusive
                      {...getFieldProps('Priority')}
                      onChange={(_, value) => {
                        setFieldValue('Priority', value)
                      }}
                      aria-label="type"
                    >
                      {articlePriority.map(value => (
                        <ToggleButton
                          value={value}
                          aria-label={String(value)}
                          key={value}
                          id={String(value)}
                          disabled={selectedItem ? value > totalInCarousel : value > totalInCarousel + 1}
                          classes={{
                            disabled: 'border border-solid'
                          }}
                        >
                          <Typography>{value}</Typography>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <UploadImage
                    onReturnValue={value => setFieldValue('PhotoData', value)}
                    defaultValue={values.PhotoData}
                    isRemovePreviewImage={isRemovePreviewImage}
                    accept="image/*,.mp4,.webm,.ogg"
                  />
                  {!!(errors.PhotoData || touched.PhotoData) && (
                    <Grid item xs={12} className="flex justify-start">
                      <div style={{ fontSize: '12px', color: 'red' }}>{errors.PhotoData || touched.PhotoData}</div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={S4}>
                <GridItem item xs={12}>
                  <Grid item xs={12}>
                    <Text variant="body2" gutterBottom>
                      Publication Text
                    </Text>
                    <ReactQuill
                      theme={'snow'}
                      id="Text"
                      ref={quillRef}
                      onKeyUp={checkCharacterCount}
                      onKeyDown={checkCharacterCount}
                      onKeyPress={checkCharacterCount}
                      value={values.Text}
                      onChange={content => setFieldValue('Text', content)}
                      modules={quillSettings}
                      formats={[
                        'header',
                        'font',
                        'size',
                        'bold',
                        'italic',
                        'underline',
                        'blockquote',
                        'list',
                        'bullet',
                        'indent',
                        'link',
                        'image',
                        'video'
                      ]}
                      bounds={'.app'}
                      placeholder={'Please enter publication text'}
                    />
                    {errors.Text && (
                      <Typography variant="caption" color="error">
                        {errors.Text}
                      </Typography>
                    )}
                  </Grid>
                  <Grid container alignItems="center" direction="row" justifyContent="center">
                    <SubmitButton
                      disabled={isLoadingUpsertPublication}
                      size="large"
                      type="submit"
                      color="primary"
                      variant="contained"
                    >
                      Save
                    </SubmitButton>
                    <SubmitButton type="button" size="large" onClick={onBack} color="secondary" variant="contained">
                      Cancel
                    </SubmitButton>
                  </Grid>
                </GridItem>
              </Grid>
            </form>
          )
        }}
      </Formik>
      <AutoHideSnackBar
        autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
        handleClose={() => setAutoHideFeedback(null)}
        message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
        severity={autoHideFeedback?.type}
        open={!!autoHideFeedback}
      />
    </>
  )
}
